import Vue from "vue"
import Router from "vue-router"
import HomePage from "../view/HomePage.vue"
// import UserPage from "../view/user.vue"

Vue.use(Router)

export default new Router({
  routes: [
    {
      path:"/",
      name:"home",
      component: HomePage,
    }
  ]
})