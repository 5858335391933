<template>
  <div class="homePage">
    <div id="canvasEle" class="pageTitle">
      <strong class="title">
        <!-- {{ head.title }} -->
        <span class="fade-enter-active">U</span>
        <span class="fade-enter-active2">S</span>
        <span class="fade-enter-active3">D</span>
        <span class="fade-enter-active4">G</span>
      </strong>
      <div class="subTitle">{{ head.subTitle }}</div>
      <div class="description">{{ head.description }}</div>
      <el-button @click="onOpenInfo" class="btn" type="primary" style="z-index: 3;">GET</el-button>
      <vueCanvasNest :config="config" :el="'#canvasEle'"></vueCanvasNest>
    </div>
    <div class="pageContent">
      <img src="../assets/blankGroup.png" />
      <div class="contentTitle">{{ content.title }}</div>
      <div class="contentDesc">{{ content.description }}</div>
      <a class="contentLink">{{ content.more }}</a>
      <Mint :onCancel="onOpenInfo" :visible="dialogVisible" />

    </div>
    <div class="detailContent">
      <div class="detailItem" v-for="(item, index) in detailList" :key="index">

        <div class="imgBox"><img :src="item.logo" /></div>
        <div class="title">{{ item.title }}</div>
        <div class="content">{{ item.content }}</div>
      </div>
    </div>
    <div class="conclusion">
      Gold is an excellentStore of Value
    </div>
    <div class="developStory">

      <!-- <el-carousel trigger="click" height="548px" :autoplay=false> -->
      <div class="bannerPg" :style="{ backgroundColor: ToggleList[0].bgColor }">
        <div class="bannerLeft">
          <div class="bannerTitle">{{ ToggleList[0].title }}</div>
          <div class="bannerDesc">{{ ToggleList[0].desc }}</div>
        </div>
        <img class="bannerImg" :src="ToggleList[0].image" />
      </div>

      <!-- </el-carousel> -->
    </div>

    <div class="footer">
      <el-form :model="rulesForm" status-icon ref="form" class="infoForm" label-position="top">
        <el-form-item class="label" label="Contact us" prop="email">
          <el-input ref="emailInput" class="emailInput" placeholder="support@midasgold.io" value="support@midasgold.io"
            disabled></el-input>
        </el-form-item>
        <el-form-item class="btnGroup">
          <el-button class="submitBtn" type="primary">SUBMIT</el-button>
        </el-form-item>
      </el-form>
      <div class="bottomBox">
        <div class="left">
          <div class="imgTitle">
            <img src="../assets/Group.png" style="height: 22px; margin-right: 6px;" />
            <!-- <img src="../assets/MidasGold.png" /> -->
            <span class="strongTitle">Midas Gold</span>
          </div>

          <div>©️ 2023 Midas Gold</div>
          <img style="margin-right: 8px;" src="../assets/cooperation1.png" />
          <img style="margin-right: 8px;" src="../assets/cooperation2.png" />
          <img src="../assets/cooperation3.png" />
        </div>
        <div class="right">
          <div v-for="obj in connectList" :key="obj.type">
            <div class="linkTitle">{{ obj.type }}</div>
            <a class="href" v-for="item in obj.children" :key="item.name" :href="item.link">
              {{ item.name }}
            </a>
          </div>
        </div>

        <div class="botterLogo">
          <div class="imgTitle">
            <img src="../assets/Group.png" style="height: 22px; margin-right: 6px;" />
            <!-- <img src="../assets/MidasGold.png" /> -->
            <span class="strongTitle">Midas Gold</span>
          </div>
          <div>©️ 2023 Midas Gold</div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

import vueCanvasNest from "vue-canvas-nest";
import Mint from "./mint.vue";

export default {
  name: 'HomePage',
  components: {
    vueCanvasNest,
    Mint,
  },
  data() {
    return {
      dialogVisible: false,
      config: {
        color: "255,255,255",
        pointColor: "255,255,255",
        opacity: 1,
        zIndex: 2,
        count: 99,
      },
      head: {
        title: "USDG",
        subTitle: "USDG Choose a better money",
        description: "Earn yield with first money backed by Physical Gold",
      },
      content: {
        title: 'Money That’s Better by Design',
        description: "A rebasing stablecoin backed by a basket of tokenized yield producing Physical Gold.",
        more: "For more information, visit our docs.",
      },
      detailList: [
        {
          logo: require("../assets/Frame1.png"),
          title: 'Backed by Physical Gold',
          content: "Backed by Physical Gold\nMDG is backed by tokenized Physical Gold. Leveraging Physical Gold's reliable appreciation against fiat, we've created an inflation-resistant money that strengthens over time, returning real yield back to holders."
        },
        {
          logo: require("../assets/Frame2.png"),
          title: 'Daily Yield',
          content: "MDG returns real yield back to its holders.Profit collected from the mining properties is distributed daily in the form of a native rebase. As the value of Physical Gold in the treasury increases over time, so does the yield."
        },
        {
          logo: require("../assets/Frame3.png"),
          title: 'Stable in value',
          content: "MDG is pegged to the dollar so there's no volatility in the price. Further, 50% of the backing is held in USDT/USDC, meaning 1:1 redemptions are always available which ensures peg stability."
        },
        {
          logo: require("../assets/Frame4.png"),
          title: 'Auto Re-collateralizing',
          content: "If the collateralization ratio ever drops beneath 100%, then 50% of the\ngold mining yield will be automatically redirected to the treasury, recollateralizing the asset and ensuring USDG is always fully backed.distributed daily in the form of a native rebase. As the value of Physical Gold in the treasury increases over time, so does the yield."
        },
      ],
      ToggleList: [
        {
          key: "desc",
          bgColor: '#FCE6BD',
          image: require("../assets/image3.png"),
          title: "Long history as \n a Store of Value",
          desc: "The key to diversification is finding investments that are not closely correlated to one another; Gold has historically had a negative correlation to stocks and other financial instruments. Properly diversified investors combine Gold with stocks and bonds and crypto in a portfolio to reduce the overall volatility and risk."
        }
      ],
      rulesForm: {
        email: "",
      },
      connectList: [
        // {
        //   type: "My Account",
        //   children: [
        //     {
        //       name: "My profile",
        //       link: ""
        //     },
        //     {
        //       name: "My Collection",
        //       link: ""
        //     },
        //     {
        //       name: "Activity",
        //       link: ""
        //     },
        //   ],
        // },
        // {
        //   type: "Resources",
        //   children :[
        //     {
        //       name: "Discord",
        //       link: ""
        //     },
        //     {
        //       name: "Docs",
        //       link: "http://docs.midasgold.io"
        //     },
        //     {
        //       name: "Help",
        //       link: ""
        //     },
        //   ]
        // },
        // {
        //   type: "Company",
        //   children :[
        //     {
        //       name: "About",
        //       link: ""
        //     },
        //     {
        //       name: "Careers",
        //       link: ""
        //     },
        //     {
        //       name: "Terms of service",
        //       link: ""
        //     },
        //   ]
        // },
      ],
    }

  },
  methods: {
    copyEmail() {
      // 获取 <el-input> 元素的引用
      const emailInput = this.$refs.emailInput;

      // 选中输入框中的文本
      emailInput.select();

      try {
        // 复制文本到剪贴板
        document.execCommand('copy');
        this.$message.success('Email copied to clipboard');
      } catch (err) {
        this.$message.error('Failed to copy email');
      }
      // 取消选中文本
      window.getSelection().removeAllRanges();
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.get('data/login.json', {
            params: { ...this.rulesForm },
          }).then(res => {
            const { success } = res.data
            if (success) {
              console.log("成功")
            }
          }, err => {
            const { response } = err
            console.log('err', response.status)
          })
        } else {
          return false
        }
      })
    },
    onOpenInfo() {
      this.dialogVisible = !this.dialogVisible
    },
  },

}
</script>

<style lang="less" scoped>
@keyframes bounce-in {
  0% {
    transform: translateY(150px);
  }

  80% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0px);
  }
}

.fade-enter-active {
  display: inline-block;
  animation: bounce-in 1s 900ms;
}

.fade-enter-active2 {
  display: inline-block;
  animation: bounce-in 1s 1000ms;
}

.fade-enter-active3 {
  display: inline-block;
  animation: bounce-in 1s 1100ms;
}

.fade-enter-active4 {
  display: inline-block;
  animation: bounce-in 1s 1200ms;
}

.homePage {
  background: url("../assets/backgroundGold.png");
}

.pageTitle {
  font-family: "ReadexPro";
  letter-spacing: 0em;
  width: 100%;
  height: 672px;
  background: url("../assets/Rectangle.png") center 100% no-repeat;
  background-color: #111;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #F4F5F6;

  .title {
    font-family: "ReadexPro";
    // font-size: 120px;
    font-size: 100px;
    font-weight: 700;
    height: 120px;
    overflow: hidden;
  }

  .subTitle {
    font-family: "ReadexPro";
    // font-size: 70px;
    font-size: 60px;
    font-weight: 500;
    text-align: center;
  }

  .description {
    font-family: "ReadexPro";
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
  }

  .btn {
    font-family: "ReadexPro";
    width: 268px;
    height: 65px;
    border-radius: 4px;
    background: #D7B27C;
    border-color: #D7B27C;
    margin-top: 60px;
    font-size: 30px;
    color: #050505;
    font-weight: 500;
  }
}

.pageContent {
  font-family: "ReadexPro";
  letter-spacing: 0em;
  width: 100%;
  text-align: center;

  img {
    height: 96px;
    margin-top: 66px;
  }

  .contentTitle {
    font-family: "ReadexPro";
    // font-size: 80px;
    font-size: 60px;
    font-weight: 500;
    line-height: 80px;
  }

  .contentDesc {
    font-family: "ReadexPro";
    font-size: 20px;
    font-weight: 200;
    line-height: 30px;
  }

  .contentLink {
    font-family: "ReadexPro";
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
    text-decoration: dashed;
    color: #754C24;
    border-bottom: 2px solid #754C24;
  }
}

.detailContent {
  font-family: "ReadexPro";
  width: 70%;
  max-width: 1453px;
  border-radius: 47px;
  // background-color: greenyellow;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
  /* width: 100%; */
  margin: 90px auto 0;

  .detailItem {
    // width: calc(49% - 116px);
    width: calc(47% - 116px);
    height: 467px;
    background: #EBEBEB;
    padding: 0 58px;

    // margin-bottom: 10px;
    // margin-bottom: 30px;
    .imgBox {
      height: 220px;
      // background-color: red;
      display: flex;
      align-items: center;
    }

    .title {
      // font-size: 40px;
      font-size: 28px;
      font-weight: 500;
      // line-height: 80px;
      line-height: 60px;
    }

    .content {
      font-family: "ReadexPro";
      // font-size: 20px;
      // font-weight: 400;
      line-height: 25px;
      margin-top: 10px;
    }
  }

  .detailItem:nth-child(2) {
    margin-bottom: 30px;
  }

  .detailItem:nth-child(1) {
    margin-bottom: 30px;
  }
}

.conclusion {
  font-family: "ReadexPro";
  width: 800px;
  // font-size: 80px;
  font-size: 60px;
  font-weight: 500;
  line-height: 80px;
  text-align: center;
  // margin: 180px auto 100px;
  margin: 100px auto 60px;
}

.developStory {
  font-family: "ReadexPro";
  letter-spacing: 0em;
  width: 70%;
  margin: auto;
  max-width: 1453px;

  // height: 548px;
  // margin: auto;
  // position: relative;
  .bannerPg {
    // width: 100%;
    // width: 1331px;
    width: calc(100% - 140px);
    height: 548px;
    border-radius: 31px;
    padding: 0 70px;
    display: flex;
    // flex-direction: column;
    justify-content: center;
    align-items: center;

    // position: absolute;
    // top: 0;
    // left: 0;
    .bannerLeft {
      width: 60%;

      .bannerTitle {
        // font-size: 70px;
        font-size: 60px;
        font-weight: 500;
        line-height: 70px;
        text-align: left;
        // width: 740px;
        white-space: pre-line;
        /* 允许换行 */
        // word-break: break-all; /* 断开单词到下一行 */
      }

      .bannerDesc {
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        margin-top: 30px;
      }

    }

    .bannerImg {
      width: 40%;
      object-fit: cover;
    }
  }

}

.footer {
  font-family: "ReadexPro";
  height: 630px;
  background: #141414;
  margin-top: 103px;

  .infoForm {
    // width: 1453px;
    width: 80%;
    max-width: 1453px;
    margin: auto;
    padding: 113px 0px 103px;
    position: relative;
    border-bottom: 1px solid rgba(244, 245, 246, .2);
  }

  .emailInput {
    // width: 1105px;
    height: 79px;
    line-height: 79px;
    border-radius: 9px;
    // opacity: 0.1;
    // background-color:rgba(20, 20, 20, .1)
    // background-color: #F4F5F6;
  }

  /deep/ .el-form--label-top .el-form-item__label {
    font-family: "ReadexPro";

    font-size: 30px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  /deep/ .el-input__inner {
    font-family: "ReadexPro";

    height: 79px;
    line-height: 79px;
    font-size: 20px;
    font-weight: 600;
    color: rgba(244, 245, 246, 1);
    background-color: rgba(200, 200, 200, .1);
    border: rgba(200, 200, 200, .1);
  }

  .submitBtn {
    font-family: "ReadexPro";
    width: 355px;
    height: 79px;
    border-radius: 9px;
    background: #D7B27C;
    border-color: #D7B27C;
    position: absolute;
    right: 0;
    bottom: 22px;
    font-size: 30px;
    color: #242424;
    font-weight: 600;
  }

  .label .el-form-item__label {
    font-family: "ReadexPro";

    font-size: 30px;
    font-weight: 500;
    line-height: 100px;
  }

  .bottomBox {
    width: 80%;
    max-width: 1453px;
    color: #F4F5F6;
    margin: auto;
    display: flex;
    justify-content: space-between;

    .left {
      line-height: 40px;
      margin-top: 20px;

      .imgTitle {
        display: flex;
        line-height: 20px;
        margin: 10px 0;
      }

      .strongTitle {
        font-weight: 900;
      }
    }

    .right {
      display: flex;
      width: 40%;
      justify-content: space-between;
      font-size: 15px;

      .linkTitle {
        font-family: "ReadexPro";
        font-weight: 600;
        line-height: 40px;
        margin-top: 20px;
      }

      .href {
        font-family: "ReadexPro";

        display: block;
        font-size: 12px;
        font-weight: 300;
        line-height: 30px;
        color: #F4F5F6;
        text-decoration: none;
      }
    }
  }
}

/* 超小屏幕（手机，小于 768px） */
@media only screen and (max-width: 767px) {
  .pageTitle {
    text-align: center;

    .title {
      height: 80px;
      font-size: 60px;
    }

    .subTitle {
      font-size: 30px;
    }

    .description {
      font-size: 14px;
    }
  }

  .pageContent {
    .contentTitle {
      font-size: 30px;
      line-height: 40px;
    }

    .contentDesc,
    .contentLink {
      font-size: 14px;
    }
  }

  .detailContent {
    display: block;
    width: 100%;
    // padding: 0 38px;
    border-radius: 0;

    .detailItem {
      width: calc(100% - 40px);
      margin-bottom: 20px;
      padding: 20px 20px;
      height: auto;
    }
  }

  .conclusion {
    width: 100%;
    font-size: 30px;
    margin: 60px auto 30px;
    line-height: 40px;
  }

  .developStory {
    width: 100%;

    .bannerPg {
      width: calc(100% - 40px);
      padding: 20px;
      flex-direction: column;
      height: auto;

      .bannerLeft {
        width: 100%;

        .bannerTitle {
          margin-top: 30px;
          font-size: 30px;
          line-height: 40px;
        }

        .bannerDesc {
          font-size: 14px;
        }
      }

      .bannerImg {
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .footer {
    height: auto;

    .infoForm {
      padding: 40px 0px 0px;

      .btnGroup {}

      .submitBtn {
        position: relative;
        width: 150px;
        height: 56px;
        font-size: 20px;
        margin-top: 20px;
      }
    }

    .bottomBox {
      display: block;

      .left {
        display: none;
      }

      .right {
        font-size: 12px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 40px;
      }

      .botterLogo {
        border-top: 1px solid rgba(244, 245, 246, 0.2);
        display: flex;
        font-size: 12px;
        line-height: 40px;
        justify-content: space-between;
        height: 80px;
        padding-top: 20px;

        .imgTitle {
          display: flex;
          line-height: 20px;
          margin: 10px 0;
        }
      }
    }
  }
}

/* 小屏幕（平板，大于等于 768px） */
@media only screen and (min-width: 768px) {
  .botterLogo {
    display: none;
  }
}</style>