<template>
  <div id="app" >
    <!-- logo + 导航 -->
    <div class="indexPage">
      <div class="pageTitle">
        <div class="logoSpace">
          <img class="miniLogo" src="./assets/Group.png" />
          <span>Midas Gold</span>
        </div>
        <!-- <router-link class="titleLink"  v-for="(item) in navigationList"  @click.native="someLink(item.name)" :key="item.nane" :to="item.link">
          <span :style="{ color: item.name === navigationKey ? '#07111C' : '' }">{{item.name}}</span>
        </router-link> -->
        <router-link
          class="titleLink"
          v-for="(item) in filteredNavigationList"
          :key="item.name"
          :to="item.link"
          @click.native="someLink(item.name)"
        >
          <span :style="{ color: item.name === navigationKey ? '#07111C' : '' }">{{item.name}}</span>
        </router-link>
        <a
          class="titleLink"
          v-for="(item) in externalNavigationList"
          :key="item.name"
          :href="item.link"
          target="_blank"
        >
          <span>{{item.name}}</span>
        </a>
      </div>

      <!-- 小屏幕展示 -->
      <div class="iconEntrance"> 
        <div class="divAddress"> 
          <p class="addressText" v-if="userAddress">Address:...{{ userAddress.slice(-9) }}</p>
          <el-button @click="connectWallet"  class="btn" type="primary" round v-if="!userAddress">Connect</el-button>
          <el-button @click="disconnectWallet"  class="btn" type="primary" round v-if="userAddress">Disconn</el-button>
        </div>
        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            <i class="el-icon-s-fold"></i>
          </span>
          <el-dropdown-menu slot="dropdown">  
              <el-dropdown-item v-for="(item) in navigationList"  @click.native="someLink(item.name)" :key="item.name" :to="item.link">
                {{item.name}}
              </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
     

      <!-- 小屏幕展示 三方入口 -->
      <div class="otherLogo">
        <!-- <img class="linkItem" v-for="(item) in otherLink" :key="item.url" :src="item.url" /> -->
      <a
        class="linkItem"
        v-for="(item) in otherLink"
        :key="item.url"
        :href="item.link"
        target="_blank"
      >
        <img :src="item.url" />
      </a>   
        <el-button @click="connectWallet"  class="btn" type="primary" round v-if="!userAddress">Connect</el-button>
        <p  v-if="userAddress">Address: {{ userAddress }}</p>
        <el-button @click="disconnectWallet"  class="btn" type="primary" round v-if="userAddress">Disconnect</el-button>
      </div>
      <Mint :onCancel="onOpenInfo" :visible="visible" />
    </div>
    <router-view />
  </div>
</template>

<script>
import Vue from 'vue'
import Web3 from "web3";
import Mint from "./view/mint.vue";


export default {
  name: 'App',
  components: {
    Mint,
  },
  data() {
    return {
      userAddress: '',
      purchaseAmount: 0,
      paymentToken: "",
      contractAddress: "", // 替换为你的合约地址
      contract: null,
      web3: null,
      visible: false,
      navigationKey: "HOME",
      navigationList: [
        {
          link:"/",
          name: "HOME"
        },
        {
          link:"/",
          name: "MINT"
        },
        {
          link:"https://docs.midasgold.io/home/",
          name: "RESOURCES"
        },
      ],
      otherLink: [
        // {
        //   url: require("./assets/link1.png"),
        // },
        {
          url: require("./assets/link2.png"),
          link:'https://t.me/mdgprotocol'
        },
        {
          url: require("./assets/link3.png"),
          link:'https://x.com/midas_gold_0?s=11'

        }
        
      ],
    }
  },
  methods: {
    showMessage(type, title, message) {
      this.$message({
        type: type,
        dangerouslyUseHTMLString: true,
        message: `
          <div>
            <p class="title">${title}</p>
            <div>${message}</div>
          </div>
        `,
      });
    },
    async connectWallet() {
        try {
          if (window.ethereum) {
            var web3 = new Web3(window.ethereum);
            const chainId = await web3.eth.net.getId();
            // 56
            if(chainId == "56"||chainId == "0x38"){
                console.log(chainId)
            }else{
              await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x38' }], // BSC 主网的 chainId
              });
              // web3 = new Web3(window.ethereum);
              // this.showMessage('success', 'success', 'Only support BSC,switch to BSC Success');
              // return;
            }
            this.web3 = web3;
            await window.ethereum.request({ method: "eth_requestAccounts" });
            const userAddress = (await this.web3.eth.getAccounts())[0];
            this.userAddress = userAddress
            // 临时方案,将web3, userAddress 做为全局变量
            Vue.prototype.$web3 = web3
            Vue.prototype.$userAddress = userAddress
            console.log("Connected to wallet. User address:", this.userAddress);
        } else {
            this.showMessage('error', 'Error', 'Please check the network and keep MetaMask install  or another Ethereum wallet');
          }
        } catch (error) {
          console.error(error);
          this.showMessage('error', 'Error', "Failed to connect wallet. Make sure you have MetaMask installed or the network is working");
        }
      },
    disconnectWallet() {
      this.userAddress = null;
      this.ethereum = null;
      this.web3 = null;
      this.visible=false;
      Vue.prototype.$web3 = null;
      Vue.prototype.$userAddress = null;
      // this.showMessage('success', 'Success', 'disconnectWallet successed!');
    },
    // 点击某个链接
    someLink(val) {
      console.log(val, 'val')
      if (val === 'MINT') {
        this.navigationKey = 'MINT'
        this.onOpenInfo()
      }else if(val === 'HOME'){
        this.navigationKey = 'HOME'
      } else if(val === 'RESOURCES'){
        window.open("https://docs.midasgold.io/home/")
      }

    },
    onOpenInfo() {
      if (this.visible) {
        // this.navigationKey = ''
      }
      this.visible = !this.visible
    },
  },

  computed: {
    filteredNavigationList() {
      return this.navigationList.filter(item => item.link.startsWith('/'));
    },
    externalNavigationList() {
      return this.navigationList.filter(item => !item.link.startsWith('/'));
    },
  },

  mounted() {
    // 监听 MetaMask 账户切换事件
    window.ethereum.on('accountsChanged', (accounts) => {
      try {
        if (!window.ethereum) {
          this.showMessage('error', 'Error', 'Please check the network and keep MetaMask install  or another Ethereum wallet');
          return;
        }

        if (accounts.length > 0) {
          this.disconnectWallet();
          // const newAccount = accounts[0];
          // this.userAddress = newAccount;
          // Vue.prototype.$userAddress = newAccount;
          window.location.reload();

          // console.log('Account changed to:', newAccount);
        } else {
            // 账户被断开时执行的操作
          this.userAddress = null;
          this.ethereum = null;
          this.web3 = null;
          this.visible=false;
          Vue.prototype.$web3 = null;
          Vue.prototype.$userAddress = null;
          console.log('Account disconnected.');
        }
      } catch (error) {
          console.error(error);
      }
    });


	// 监听 MetaMask 链切换事件
    window.ethereum.on('chainChanged', async (chainId) => {
      try{
        if (!window.ethereum) {
          this.showMessage('error', 'Error', 'Please check the network and keep MetaMask install  or another Ethereum wallet');
          return;
        }
        // 检查是否是 Binance Smart Chain
        if (chainId === '0x38' || chainId === '56') {
          console.log('Chain changed to Binance Smart Chain (BSC)');
          // 在这里执行与链切换相关的操作
        } else {
           this.disconnectWallet();
          // 链切换为非 BSC，拒绝切换并提示用户
          console.log('Chain change to a non-supported chain.');
          this.showMessage('error', 'Error', 'Not support chain ,only support BSC');
          // window.location.reload();

        }
      } catch (error) {
        console.error(error);
      }
    });
    this.connectWallet();

  },

}
</script>

<style lang="less" scoped>
  * {
    margin: 0;
    padding: 0;
  }
  body {
    max-width: 0;
  }

  .indexPage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // width: 100%;
    padding: 0 40px;
    font-family: "ReadexPro";

    .pageTitle {
      font-family: "ReadexPro";

      height: 93px;
      font-size: 18px;
      display: flex;
      align-items: center;
      .logoSpace {
        height: 22px;
        font-weight: 800;
        display: flex;
        align-items: center;
        margin-right: 108px;
        .miniLogo {
          height: 22px;
          margin-right: 8px;
        }

      }
      .titleLink {
        font-family: "ReadexPro";

        // color: #07111C;
        color: #c0c4cc;
        text-decoration: none;
        font-family: "ReadexPro";
      
        font-weight: 400;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: left;
        margin-right: 40px;
      }
    }
    .otherLogo {
      display: flex;
      align-items: center;
      .linkItem {
        margin-left: 13px;
      }
      .btn {
        font-family: "ReadexPro";
        margin-left: 24px;
        font-weight: 400;
        background: #111111;
        border-color: #111111;
      }
    }
    
  }
  .addressText {
      font-size: 6px; /* 适当的字体大小 */
  }

  @media only screen and (max-width: 767px) {
    .indexPage {
      padding: 0 20px;
    }
    .titleLink {
      display: none;
    }
    .indexPage  .otherLogo {
      display: none;
    }
    .iconEntrance {
      font-size: 20px;
      display: flex;
      cursor: pointer;

      align-items: center;
      .divAddress{
        flex-direction: column;
      }
      .el-dropdown {
        font-size: 30px;
        margin-left: 8px;
      }
      .el-button.is-round {
        padding: 8px 24px;
      }
    }
  }
   /* 小屏幕（平板，大于等于 768px） */
  @media only screen and (min-width: 767px) {
    .iconEntrance {
      display: none;
    }
  }


</style>
