import Vue from 'vue'
import VueRouter from "vue-router"
import ElementUI from 'element-ui';
import axios from 'axios';
import App from './App.vue'
import router from './router';


// import "element-ui/lib/theme-chalk/index.css"
import "../theme/index.css"
import "../theme/common.css"

import "./global.less"

// 使用Vue-Router
Vue.use(VueRouter);
Vue.use(ElementUI);

Vue.config.productionTip = false
Vue.prototype.$axios = axios

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
