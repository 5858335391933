<template>
  <el-dialog :visible="visible" class="infoModal" @close='onCancel'>
    <div class="loginBox">
      <div class="showNum">
        <div class="textLine">
          <span>Withdrawed:{{ withDrawedAmount }}
            <span v-if="lockedAmount <= 0">MDGS</span>
          </span>
        </div>


        <div class="lockedText">
          <div class="numSpace">
            Locked:
            <span v-html="'&nbsp'"></span>
            <span class="numShow">
              {{ lockedAmount }}
              <span v-if="lockedAmount <= 0">MDGS</span>
            </span>
          </div>


        </div>
        <div class="lockedText">
          <div class="numSpace">
            canWithdraw:
            <span v-html="'&nbsp'"></span>
            <span class="numShow">
              {{ canWithdrawAmount }}
              <span v-if="canWithdrawAmount <= 0">MDGS</span>

            </span>
          </div>
          <el-button type="primary" class="withdraw-button" @click="withdrawFunds">Withdraw</el-button>

        </div>
      </div>

      <el-tabs v-model="activeName">
        <el-tab-pane label="Mint" name="Mint"></el-tab-pane>
        <el-tab-pane label="Wrap" name="Wrap" disabled></el-tab-pane>
      </el-tabs>
      <span class="totalShow" @click="checkAllowance">Granted Total Allowance: {{ totalAllowance || '0.00' }}
        {{ selectLabel }}</span>

      <el-form :model="rulesForm" :rules="rules" ref="form">

        <el-form-item class="formItem" label="From" prop="number">


          <span class="tip" @click="fetchBalance">Balance: {{ balance || '0.00' }} {{ selectLabel }}</span>

          <el-input v-model="rulesForm.number" @input="numberChange">

            <el-select style="width: 110px;" v-model="selectValue" slot="prepend" @change="priceChange" placeholder="请选择">
              <el-option v-for="item in currencyList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-button slot="append" type="primary" @click="fillMax">MAX</el-button>
          </el-input>
        </el-form-item>
        <el-form-item class="formItem" label="To" prop="convert">
          <el-input readonly v-model="rulesForm.convert" style="height: 58;">
            <template slot="prepend">{{ unit }}</template>
          </el-input>
        </el-form-item>
        <div class="resultSpace">
          <div class="textLine">
            <span>Price</span>
            <span class="percentNum"> 1 {{ selectLabel }} = {{ scale }} {{ unit }}</span>
          </div>
          <div class="textLine">
            <span>Amount received</span>
            <span class="percentNum">{{ parseFloat((rulesForm.number * scale).toFixed(4)) }} {{ unit }}</span>
          </div>
        </div>
        <el-form-item class="btnSpace">
          <el-button class="btnGroup" @click="validateAndConvert('form')" type="primary">{{ btnText }}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template> 

<script>

import usdabi from "../abi/usd-bsc.json";

import swaptokenabi from "../abi/swap-token.json";

import BigNumber from "bignumber.js";



export default {
  name: "loginPage",
  props: {
    visible: Boolean,

    onCancel: Function,
    callback: Function,
  },
  data() {
    return {
      activeName: "Mint",
      scale: 0,
      unit: "MDGS",
      rulesForm: {
        number: 0,
        convert: 0,
      },
      selectValue: '0x55d398326f99059ff775485246999027b3197955',
      selectLabel: 'USDT',
      tokenSwapContract: "0xD54B05b2E82b0ad7918ff5951d123dADEF6BF5b5",
      currencyList: [
        {
          label: 'USDT',
          value: "0x55d398326f99059ff775485246999027b3197955",
        },
        {
          label: "USDC",
          value: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
        },

      ],
      rules: {
        number: [
          { required: true, message: "required" },
          { pattern: /^(?!0\d)(\d+)?(\.\d{1,2})?$/, message: "Allow up to two decimal places" },
          { validator: this.checkMax },
        ],
      },
      btnText: "APPROVE",
      balance: 0.00,
      lockedAmount: 0.0000,
      withDrawedAmount: 0.0000,
      totalAllowance: 0.00,
      canWithdrawAmount: 0.0000


    }
  },
  methods: {
    checkMax(rule, value, callback) {
      if (this.balance && parseFloat(value) > parseFloat(this.balance)) {
        callback(new Error(`Not more than ${this.balance}`));
      } else {
        //校验通过
        callback();
      }
    },

    async fetchBalance() {
      console.log('this.$web3', this.$web3)
      console.info("start fetch");
      if (this.$web3 && this.$userAddress) {
        try {
          var abi = usdabi;
          var contractAddress = this.selectValue;


          var ethContract = new this.$web3.eth.Contract(abi, contractAddress);
          var balance = await ethContract.methods.balanceOf(this.$userAddress).call();
          this.balance = this.$web3.utils.fromWei(balance, 'ether');
          this.balance = parseFloat(this.balance).toFixed(2);
        } catch (error) {
          console.error(error);
          this.showMessage('error', 'Error', 'Check your network and refresh again to get balance of wallet!');
        }
      } else {
        this.showMessage('error', 'Error', "The Wallet Disconnected . Make sure The MetaMask installed and Connected");

      }
    },


    async fetchGoldPrice() {
      try {

        var abi = swaptokenabi;
        var contractAddress = this.tokenSwapContract;
        const contract = new this.$web3.eth.Contract(abi, contractAddress);
        // 调用查询函数
        var res = await contract.methods.price().call();
        console.info(res)
        this.scale = parseFloat(1 / (new BigNumber(res).dividedBy(100).decimalPlaces(2).toString(10))).toFixed(4);
      } catch (error) {
        console.error(error);
        this.showMessage('error', 'Error', 'Check your network and refresh again to get the sellPrice of MDGS!');
      }
    },
    async fetchSwapInnerBalance() {
      try {
        var abi = swaptokenabi;
        var contractAddress = this.tokenSwapContract;
        const contract = new this.$web3.eth.Contract(abi, contractAddress);
        // 调用查询函数
        var res = await contract.methods.balances(this.$userAddress).call();
        // 将initial和withdrawed从字符串转换为BigNumber或BN
        var initial = new this.$web3.utils.BN(res.initial);
        var withdrawed = new this.$web3.utils.BN(res.withdrawed);

        // 计算locked作为BN或BigNumber
        var locked = initial.sub(withdrawed);

        // 将withDrawedAmount和lockedAmount从Wei转换为以太币，并四舍五入到4位小数
        this.withDrawedAmount = parseFloat(this.$web3.utils.fromWei(res.withdrawed, 'ether')).toFixed(4);
        this.lockedAmount = parseFloat(this.$web3.utils.fromWei(locked.toString(), 'ether')).toFixed(4);
      } catch (error) {
        console.error(error);
        this.showMessage('error', 'Error', 'Check your network and  Try it again to get locked amount!');
      }
    },

    async getUserCanWithdraw() {
      try {
        var abi = swaptokenabi;
        var contractAddress = this.tokenSwapContract;
        const contract = new this.$web3.eth.Contract(abi, contractAddress);
        // 调用查询函数
        var res = await contract.methods.getUserCanWithdraw(this.$userAddress).call();
        this.canWithdrawAmount = parseFloat(this.$web3.utils.fromWei(res.toString(), 'ether')).toFixed(4);
        console.info(res)
      } catch (error) {
        console.error(error);
        // this.showMessage('error', 'Error', 'Check your network and  Try it again to getUserCanWithdraw!');
      }
    },

    async withdrawFunds() {
      if (this.$web3 && this.$userAddress) {
        try {
          // 检查可用的锁定额是否大于0
          if (this.lockedAmount <= 0) {
            // 锁定额大于0，显示错误消息
            this.showMessage('error', 'Error', 'You cannot withdraw funds for zero locked');
            // 提前退出函数，不继续执行后续操作
            return;
          }
          const contract = new this.$web3.eth.Contract(swaptokenabi, this.tokenSwapContract);
          // 步骤 4: 执行领取
          const transactionObject = {
            to: this.tokenSwapContract,
            from: this.$userAddress,
            data: contract.methods.withdraw().encodeABI()
          };

          const gasPrice = await this.$web3.eth.getGasPrice();
          const tx = {
            ...transactionObject,
            gasPrice: gasPrice,
          };

          const receipt = await this.$web3.eth.sendTransaction(tx);
          // 交易已被确认
          console.log('Transaction Withdraw confirmed:', receipt);
          this.showMessage('success', 'Success', 'Withdraw  success!');
          this.refresh();

        } catch (error) {
          console.error('Error:', error);
          this.showMessage('error', 'Error', 'Withdraw SendTransaction ERROR, please check network and  Try it again!');
          // 处理其他错误，例如合约调用失败
        }
      } else {
        this.showMessage('error', 'Error', "The Wallet Disconnected . Make sure The MetaMask installed and Connected");

      }
    },


    async executeConversion() {
      try {
        // 步骤 2: 检查用户的授权状态
        const allowance = await this.checkAllowance();
        const amount = this.$web3.utils.toWei(this.rulesForm.number, "ether"); // 转账金额parseInt(this.rulesForm.number * 1000000000000000000);



        if (parseInt(allowance) >= parseInt(amount)) {
          // 用户已授权足够的额度，可以执行兑换
          const type = this.selectLabel === 'USDT' ? 1 : 2;
          const contract = new this.$web3.eth.Contract(swaptokenabi, this.tokenSwapContract);

          // 步骤 4: 执行转换
          const transactionObject = {
            to: this.tokenSwapContract,
            from: this.$userAddress,
            data: contract.methods.buyTokens(amount, type).encodeABI()
          };

          const gasPrice = await this.$web3.eth.getGasPrice();
          const tx = {
            ...transactionObject,
            gasPrice,
          };

          const receipt = await this.$web3.eth.sendTransaction(tx);
          // 交易已被确认
          console.log('Transaction executeConversionconfirmed:', receipt);
          this.showMessage('success', 'Success', 'Mint  success!');
          this.refresh();
        } else {
          // 步骤 3: 请求用户授权额外的代币
          await this.requestAuthorization();
        }
      } catch (error) {
        this.showMessage('error', 'Error', 'Mint failed ,Please refresh and Try it again!');
        // 处理其他错误，例如合约调用失败
      }
    },
    // 检查用户的授权状态
    async checkAllowance() {
      try {

        var abi = usdabi;
        const tokenContract = new this.$web3.eth.Contract(abi, this.selectValue);
        const allowance = await tokenContract.methods.allowance(this.$userAddress, this.tokenSwapContract).call();
        this.totalAllowance = this.$web3.utils.fromWei(allowance, 'ether');
        return allowance;
      } catch (error) {
        console.error(error);
        this.showMessage('error', 'Error', ' Auto check granted Allowance failed ,Please refresh and Try it again!');
      }

    },

    // 请求用户授权额外的代币
    async requestAuthorization() {
      try {

        var abi = usdabi;

        const tokenContract = new this.$web3.eth.Contract(abi, this.selectValue);// 代币合约
        const amountToApprove = this.$web3.utils.toWei(this.rulesForm.number, "ether"); // 授权数量（单位：代币数量）
        const gasPrice = await this.$web3.eth.getGasPrice();

        const userAddress = this.$userAddress;
        await tokenContract.methods.approve(this.tokenSwapContract, amountToApprove).send({
          from: userAddress,
          gasPrice: gasPrice
        });
        this.showMessage('success', 'Success', 'Your operation Approve was successed!');
      } catch (error) {
        console.error(error);
        this.showMessage('error', 'Error', 'Your operation Approve failed ,Please refresh and Try it again!');
      }
      this.refresh();
    },
    showMessage(type, title, message) {
      this.$message({
        type: type,
        dangerouslyUseHTMLString: true,
        message: `
          <div>
            <p class="title">${title}</p>
            <div>${message}</div>
          </div>
        `,
      });
    },
    async validateAndConvert(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // 验证通过
          if (parseFloat(this.rulesForm.convert) > 0) {
            // convert 大于 0，执行兑换
            this.executeConversion();
          } else {
            // convert 不大于 0，给出提示信息
            this.showMessage('error', 'Error', 'Amount received must be greater than 0');
          }
        } else {
          return false
        }
        this.refresh();
      })
    },

    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    // 修改输入内容
    numberChange(value) {
      if (/^(?!0\d)(\d+)?(\.\d{1,2})?$/.test(value)) {
        this.rulesForm.convert = (value * this.scale).toFixed(4);
      }
    },
    // max 填充最大值
    fillMax() {
      this.rulesForm = {
        number: this.balance || 0,
        convert: parseFloat(((this.balance || 0) * this.scale).toFixed(4))
      }
    },
    // 修改类型
    priceChange(value) {
      this.selectValue = value
      this.selectLabel = value ? this.currencyList.find(ele => ele.value === value).label : ''
      this.fetchBalance();
    },
    updateButtonText() {
      if (parseFloat(this.totalAllowance) >= parseFloat(this.rulesForm.number)) {
        // 如果总授权大于或等于输入的数量，显示 "MINT"
        this.btnText = "MINT";
      } else {
        // 否则显示 "APPROVE"
        this.btnText = "APPROVE";
      }
    },
    refresh() {
      if (this.$web3 && this.$userAddress) {
        this.checkAllowance();
        this.fetchGoldPrice();
        this.fetchBalance();
        this.fetchSwapInnerBalance();
        this.getUserCanWithdraw();
      } else {
        this.showMessage('error', 'Error', "The Wallet Disconnected . Make sure The MetaMask installed and Connected");

      }

    }

  },

  watch: {
    visible: {
      handler(newVisible) {
        if (newVisible) {
          if (this.$web3 && this.$userAddress) {
            this.checkAllowance();
            this.fetchGoldPrice();
            this.fetchBalance();
            this.fetchSwapInnerBalance();
            this.getUserCanWithdraw();
          } else {
            this.showMessage('error', 'Error', "The Wallet Disconnected . Make sure The MetaMask installed and Connected");

          }

        }
      },

      // immediate: true, // 立即调用一次以确保初始状态
    },
    'rulesForm.number': function () {
      // 在这里更新按钮文本
      this.updateButtonText();
    },
    'totalAllowance': function () {
      // 在这里更新按钮文本
      this.updateButtonText();
    }
  },

}
</script>

<style lang="less" scoped>
/* 添加余额信息的样式 */
.showNum {
  color: #07111C;
  text-align: left;
  border: 1px solid #E7E7E7;
  border-radius: 1em;
  padding: 10px;
  margin-bottom: 10px;

  .lockedText {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // height: 40px;
    font-size: 18px;
    font-weight: 400;
    line-height: 40px;

    .numSpace {
      display: flex;
    }

    .numShow {
      font-size: 18px;
      font-weight: 600;
    }

    .withdraw-button {
      height: 32px;
      padding: 0 12px;
      font-size: 18px;
      font-weight: 400;
      color: #07111C;

      &:focus,
      &:hover {
        color: #07111C;
      }
    }
  }
}

.totalShow {
  width: 100%;
  display: inline-block;
  text-align: left;
}

.balanceInfo {
  text-align: left;
  margin-bottom: 20px;
}

.infoModal {
  /deep/ .el-dialog {
    border-radius: 30px;
    // box-shadow: 0px, 40px, 80px, 0px, rgba(46,92,255,0.1);
    box-shadow: 0px 40px 80px 0px rgba(46, 92, 255, 0.1);
    background-color: white;
    // background: linear-gradient(to bottom, rgba(46,92,255,0.1), rgba(46,92,255,0));
    border: 1px solid white;
    // z-50 data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] focus:outline-none h-[calc(100%-16px)] w-[calc(100%-16px)] flex flex-col gradient-bg border-2 border-white shadow-[0px_40px_80px_0px_#2E5CFF33] rounded-[32px] overflow-y-auto md:h-fit md:max-h-[min(calc(100%-1rem),768px)] md:w-[580px] md:overflow-y-hidden drop-shadow-[0px_40px_80px_rgba(46,92,255,0.2)]
  }
}

.loginBox {
  // padding: 16px;
  width: 100%;
  // height: 523px;
  margin: 0 auto;

  /deep/ .el-tabs__item {
    font-size: 18px;
    font-weight: 400;
  }

  /deep/ .el-tabs__item.is-active {
    color: #07111C;
  }
}

.resultSpace {
  line-height: 32px;

  .textLine {
    display: flex;
    justify-content: space-between;
    font-size: 18px;

    .percentNum {
      font-size: 18px;
      font-weight: 400;
      color: #07111C;
    }
  }
}

.btnSpace {
  // border-top: 1px solid rgba(227, 230, 232, 1);
}

.formItem {
  height: 86px;
  background-color: #F1F1F1;
  border-radius: 5px;
  // padding: 10px 22px 24px;
  padding: 10px;
  margin-top: 10px;

  // border: 1px solid #e3e6e8;
  .tip {
    float: right;
    font-size: 16px;
    font-weight: 400;
    color: #07111C;
  }

  // .el-form-item__content .el-input-group {
  //   height: 58px;
  // }
  /deep/.el-input-group__prepend .el-input__inner,
  /deep/.el-input-group__prepend {
    font-size: 20px;
    font-weight: 400;
    color: #07111C;
    background: #fff;
    font-family: "ReadexPro";
  }

  /deep/.el-input-group__append {
    background: #fff;
    font-size: 18px;
    font-weight: 400;
    padding: 0px;
  }

  /deep/ .el-input-group__append button.el-button {
    height: 32px;
    background: #D7B27C;
    color: #07111C;
    line-height: 0;
    margin-right: 0px;
  }

  /deep/ .el-input__inner {
    // height: 58px;
    font-size: 18px;
    border-right: none;
  }

  /deep/ .el-input.is-disabled .el-input__inner {
    background: #fff;
    font-size: 18px;
    border-radius: 10px;
  }
}

/deep/ .el-form-item__label {
  font-size: 18px;
  font-weight: 400;
  color: #07111C;
}

/deep/ .el-form-item__content {
  text-align: center;
}

.btnGroup {
  text-align: center;
  width: 100%;
  height: 44px;

  text-align: center;
  border-radius: 40px;
  margin-top: 10px;

  font-size: 20px;
  font-weight: 400;
  color: #07111C;

  &:focus,
  &:hover {
    color: #07111C;
  }
}

@media only screen and (max-width: 768px) {
  .showNum {
    .lockedText {
      display: block;
    }
  }

  .el-dialog__wrapper {
    /deep/ .el-dialog {
      width: 90%;

      .el-dialog__body {
        padding: 10px;
      }
    }
  }

}

@media only screen and (min-width: 768px) {
  .el-dialog__wrapper {
    /deep/.el-dialog {
      width: 678px;

      .el-dialog__body {
        padding: 30px;
      }
    }
  }
}</style>